import { useState, useEffect } from 'react';

const isClient = typeof window !== 'undefined';

export const useWindowSize = () => {
  const [state, setState] = useState<{ width: number; height: number }>({
    width: isClient ? window.innerWidth : Infinity,
    height: isClient ? window.innerHeight : Infinity,
  });

  useEffect(() => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handler, { passive: true });

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return state;
};
